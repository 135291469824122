import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import "../css/AboutUs.css";
import { Container } from 'react-bootstrap';

export default function AboutUs(){
    return(
        <div className='about my-4'>
            <Container>
            <h1 className='about-header pt-5'>About Us</h1>
            <div className='about-section'>
            <p>
            Sansar Constructions is a company offering integrated planning, structural designs, 
            Execution of works, Repair & Rehabilitation of Buildings, and Consultancy services for all types of Infrastructure projects. 
            This Company is founded by Dr.A.ANNADURAI.B.E.(Civil), M.E.(Structural),Ph.D., who has rich experience in the construction of Institutional, Commercial, Official, Residential buildings, Roads, Culverts, etc., and has integrity and coordination with Client, Architect, PWD Engineers, suppliers, and Employees working under him at various level.
            The company is always maintaining innovative planning and Design, Quality in Construction, and Excellent Consultancy services.
            </p>
            <StaticImage
                alt="planning"
                src="../images/annadurai.png"
                className='image-style'
            />
            </div>
        </Container>
        </div>
    )
}